import CryptoenterCommissionModel from '@/models/admin/CryptoenterCommissionModel'
import CurrencyTokenModel from '@/models/admin/CurrencyTokenModel'
import LionCourseModel from '@/models/admin/LionCourseModel'
import OrganizationModel from '@/models/admin/OrganizationModel'
import OrganizationCommissionModel from '@/models/admin/OrganizationCommissionModel'
import ProcessTypeCurrencyModel from '@/models/admin/ProcessTypeCurrencyModel'
import GasEthCommissionModel from '@/models/admin/GasEthCommissionModel'
import BlockingUsersModel from '@/models/admin/BlockingUsersModel'
import AutoExchangePairsSettingsModel from '@/models/admin/AutoExchangePairsSettingsModel'
import AutoExchangeRateStepsModel from '@/models/admin/AutoExchangeRateStepsModel'
import AutoExchangeCommissionReserveModel from '@/models/admin/AutoExchangeCommissionReserveModel'
import SystemUserCreateModel from '@/models/admin/SystemUserCreateModel'
import UserKycStatusModel from '@/models/admin/UserKycStatusModel'
import DepositFiatModel from '@/models/admin/DepositFiatModel'
import AutoExchangeControlModel from '@/models/admin/AutoExchangeControlModel'
import AutoExchangeCancelOffersModel from '@/models/admin/AutoExchangeCancelOffersModel'
import AutoExchangeLionCommissionModel from '@/models/admin/AutoExchangeLionCommissionModel'
import P2PSettings from '@/models/admin/P2PSettings'
import P2PSecurity from '@/models/admin/P2PSecurity'

export const CC_PROCESS_TYPES = [
  'MOVE',
  'EXCHANGE_WITH_BOTH_COMMISSION',
  'TRANSFER_SENDER_COMMISSION',
  'TRANSFER_RECEIVER_COMMISSION',
  'DEPOSIT_FUNDS',
  'WITHDRAWAL_FUNDS',
  'REFERRAL_REVENUE',
  'AUTOEXCHANGE',
  'REVENUE_TRANSFER_COMMISSION',
  'REVENUE_TRANSFER_LION_SALE',
  'REFERRAL_REVENUE_TRANSFER'
]

export const KYC_STATUS_NAMES = {
  VERIFIED: 'VERIFIED',
  NOT_VERIFIED: 'NOT_VERIFIED'
}

export const KYC_STATUSES = Object.values(KYC_STATUS_NAMES)

export const USER_STATUS_NAMES = {
  ACTIVATED: 'ACTIVATED',
  BLOCKED: 'BLOCKED'
}

export const USER_STATUSES = Object.keys(USER_STATUS_NAMES)

export const ORGANIZATION_TYPE_NAMES = {
  CRYPTOENTER: 'CRYPTOENTER',
  BANK: 'BANK'
}

export const ORGANIZATION_TYPES = Object.keys(ORGANIZATION_TYPE_NAMES)

export const ADMIN_METHODS_MAP = {
  cryptoenterCommission: '/admin/cryptoenter-commission',
  currencyToken: '/admin/currency-token',
  lionCourse: '/admin/lion-exchange-rate',
  createOrganization: '/admin/organization',
  organizationCommission: '/admin/commission',
  processTypeCurrencyLimits: '/admin/currency-constraints',
  gasEthCommission: '/admin/gas-commission',
  blockingUsers: '/admin/user-status',
  autoExchangePairsSettings: '/admin/autoexchange-constraints',
  autoExchangeRateSteps: '',
  autoExchangeCommissionReserve: '',
  systemUserCreate: '/admin/system-user',
  userKycStatus: '/admin/user-kyc-status',
  depositFiat: '/admin/deposit-fiat',
  autoExchangeControl: '/admin/suspend-matching',
  p2pSettings: '/admin/p2p-currency-param',
  p2pClearAll: '/admin/p2p-clear',
  p2pSecurity: '/admin/p2p-security',
  autoExchangeCancelOffers: '/admin/cancel-all-autoexchanges',
  autoExchangeLionCommission: '/admin/ae-request-commission',
}

export const ADMIN_CONSTRUCTORS_MAP = {
  cryptoenterCommission: CryptoenterCommissionModel,
  currencyToken: CurrencyTokenModel,
  lionCourse: LionCourseModel,
  createOrganization: OrganizationModel,
  organizationCommission: OrganizationCommissionModel,
  processTypeCurrencyLimits: ProcessTypeCurrencyModel,
  gasEthCommission: GasEthCommissionModel,
  blockingUsers: BlockingUsersModel,
  autoExchangePairsSettings: AutoExchangePairsSettingsModel,
  autoExchangeRateSteps: AutoExchangeRateStepsModel,
  autoExchangeCommissionReserve: AutoExchangeCommissionReserveModel,
  systemUserCreate: SystemUserCreateModel,
  userKycStatus: UserKycStatusModel,
  depositFiat: DepositFiatModel,
  autoExchangeControl: AutoExchangeControlModel,
  p2pSettings: P2PSettings,
  p2pSecurity: P2PSecurity,
  autoExchangeCancelOffers: AutoExchangeCancelOffersModel,
  autoExchangeLionCommission: AutoExchangeLionCommissionModel,
}

export const SERVICE_MAN_ROUTE_NAMES = ['cashierView', 'rootCashierView']
